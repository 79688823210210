import React from 'react';

import { Box, Stack, LinearProgress } from '@mui/material';

import { useStyles } from './loader.styles';

export function Loader({ active }) {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        ...useStyles.root,
        opacity: active ? 1 : 0,
        zIndex: active ? 10000 : -2,
      }}
    >
      <Box sx={{ ...useStyles.loader }}>
        <LinearProgress aria-label="preloader" />
      </Box>
    </Stack>
  );
};