import { customization } from '../../layout/Theme';

export const useStyles = {
  root: {
    background: customization.colors.greyDarker,
  },
  
  button: {
    borderRadius: customization.borderRadius.medium,
  },
  
  buttonAccept: {
    color: customization.colors.white,
    background: customization.colors.primary,
    fontFamily: customization.typography.fontFamily,
  },
  
  buttonDecline: {
    background: 'transparent',
    color: customization.colors.white,
    fontFamily: customization.typography.fontFamily,
    border: `1px solid ${customization.colors.white}`,
  },
};