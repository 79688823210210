import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Button, IconButton } from 'gatsby-theme-material-ui';

import {
  Box,
  Menu,
  Stack,
  Tooltip,
  MenuItem
} from '@mui/material';
import { ExpandMoreRounded } from '@mui/icons-material';

import { useStyles } from './header.desktop.styles';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';
import { setNavBannerHeightDesktop } from '../../store/appSlice';
import { useResetFormServices } from '../../hooks/useResetFormServices';

export function HeaderDesktop({ location }) {
  const dispatch = useDispatch();
  const { siteNavigation } = useSiteMetadata();
  const { resetFormServices } = useResetFormServices();

  // store nav height
  const navBanner = useRef(null);
  useEffect(() => {
    const setNavHeight = () => {
      const target = navBanner.current;
      if (target.classList.contains('shrink')) {
        dispatch(setNavBannerHeightDesktop(target.offsetHeight));
      }
    };

    if (navBanner) {
      window.addEventListener('scroll', setNavHeight);
    }
    return () => window.removeEventListener('scroll', setNavHeight);
  }, [navBanner]);

  // handle services menu
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleOpen = e => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  // handle nav shrink
  const [isShrink, setIsShrink] = useState(false);

  useEffect(() => {
    const shrink = () => window.scrollY > 100 ? setIsShrink(true) : setIsShrink(false);
    window.addEventListener('scroll', shrink);

    return () => window.removeEventListener('scroll', shrink);
  }, [isShrink]);

  return (
    <Box
      ref={navBanner}
      component="header"
      style={{ ...useStyles.root }}
      className={isShrink ? 'shrink' : ''}
    >
      <Box component="nav" sx={{ ...useStyles.nav }} >
        <Stack
          p={0}
          m={0}
          component="ul"
          direction="row"
          alignItems="center"
          sx={{ ...useStyles.linkWrap }}
        >
          <Box component="li">
            <Tooltip title="Retour à l'accueil" placement="left" arrow>
              <IconButton
                to="/"
                size="large"
                aria-label="back home"
                sx={{
                  ...useStyles.logo,
                  width: isShrink ? 100 * 0.7 : 100,
                  height: isShrink ? 100 * 0.7 : 100,
                }}
                className={location === '/' ? 'disabled' : ''}
              >
                <Box className="nav-logo" />
              </IconButton>
            </Tooltip>
          </Box>
          {siteNavigation.links.map((val, i) => (
            <Box component="li" key={i}>
              {val.ref === 'services' ? (
                <Box>
                  <Button
                    to={val.url}
                    size="large"
                    aria-haspopup="true"
                    onClick={handleOpen}
                    partiallyActive={true}
                    activeClassName="active"
                    id="services-dropdown-button"
                    aria-expanded={isOpen ? 'true' : undefined}
                    aria-controls={isOpen ? 'services-menu' : undefined}
                    endIcon={<ExpandMoreRounded className={isOpen ? 'active' : ''} />}
                  >
                    {val.title}
                  </Button>
                  <Menu
                    open={isOpen}
                    id="services-menu"
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    sx={{ ...useStyles.dropdownWrap }}
                    MenuListProps={{ 'aria-labelledby': 'services-dropdown-button' }}
                  >
                    {siteNavigation.services.map((val, i) => (
                      <MenuItem
                        key={i}
                        to={val.url}
                        component={Link}
                        onClick={handleClose}
                        className="subNavLink"
                        activeClassName="active"
                        sx={{ ...useStyles.subNavLink }}
                      >
                        {val.title}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              ) : (
                val.ref === 'contact' ? (
                  <Button
                    to={val.url}
                    className={val.ref}
                    activeClassName="active"
                    onClick={resetFormServices}
                    variant={location === val.url ? 'outlined' : 'contained'}
                  >
                    {val.title}
                  </Button>
                ) : (
                  <Button
                    size="large"
                    to={val.url}
                    activeClassName="active"
                  >
                    {val.title}
                  </Button>
                )
              )}
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};
