import { customization } from '../../layout/Theme';

export const useStyles = {
  root: {
    p: {
      sm: '5vh 10vw !important',
    },
  },

  nav: {
    '& a': {
      color: 'text.primary',
    },
  },

  subtitle: {
    mb: 1,
    fontWeight: theme => theme.typography.fontWeightBold,
  },

  contactWrap: {
    mb: {
      xs: 0.75,
      sm: 0,
    },
    fontSize: theme => theme.typography.pxToRem(14),

    '& h3': {
      mb: 1.75,
    },

    '& a': {
      lineHeight: '200%',
    },
  },

  contactLinkWrap: {
    pl: 1,
  },

  socialNetworksWrap: {
    mt: 1.5,
  },

  icon: {
    color: 'text.primary',
  },

  partnersWrap: {
    '& .material-icons': {
      mr: 0.5,
      display: 'flex',
    },

    '& a': {
      color: 'text.primary',
    },
  },

  footerBottomBanner: {
    p: theme => theme.spacing(2, 2, 0.5, 2),
    backgroundColor: customization.colors.greyDarker,
  },

  linkWrap: {
    flexWrap: 'wrap',
    justifyContent: {
      xs: 'space-between',
      sm: 'space-evenly',
    },
    
    '& a span': {
      fontSize: theme => theme.typography.pxToRem(12),
    },
  },

  link: {
    mr: 2,
    mb: {
      xs: 1,
      sm: 0,
    },
  },

  copyright: {
    fontSize: theme => theme.typography.pxToRem(10),
  },
};