exports.components = {
  "component---src-routes-404-js": () => import("./../../../src/routes/404.js" /* webpackChunkName: "component---src-routes-404-js" */),
  "component---src-routes-about-js": () => import("./../../../src/routes/about.js" /* webpackChunkName: "component---src-routes-about-js" */),
  "component---src-routes-contact-js": () => import("./../../../src/routes/contact.js" /* webpackChunkName: "component---src-routes-contact-js" */),
  "component---src-routes-index-js": () => import("./../../../src/routes/index.js" /* webpackChunkName: "component---src-routes-index-js" */),
  "component---src-routes-legal-js": () => import("./../../../src/routes/legal.js" /* webpackChunkName: "component---src-routes-legal-js" */),
  "component---src-routes-privacy-policy-js": () => import("./../../../src/routes/privacy-policy.js" /* webpackChunkName: "component---src-routes-privacy-policy-js" */),
  "component---src-routes-services-care-js": () => import("./../../../src/routes/services/care.js" /* webpackChunkName: "component---src-routes-services-care-js" */),
  "component---src-routes-services-easephone-js": () => import("./../../../src/routes/services/easephone.js" /* webpackChunkName: "component---src-routes-services-easephone-js" */),
  "component---src-routes-services-index-js": () => import("./../../../src/routes/services/index.js" /* webpackChunkName: "component---src-routes-services-index-js" */),
  "component---src-routes-services-jokair-js": () => import("./../../../src/routes/services/jokair.js" /* webpackChunkName: "component---src-routes-services-jokair-js" */)
}

