import React from 'react';
import { useSelector } from 'react-redux';
import { Link, Button, IconButton } from 'gatsby-theme-material-ui';

import {
  Box,
  Icon,
  Grid,
  Stack,
  Tooltip
} from '@mui/material';
import { KeyboardArrowUpRounded } from '@mui/icons-material';

import { useStyles } from './footer.styles';
import { Reveal } from '../../hooks/reveal';
import { Subtitle, TextContent } from '../utils/utils';
import piramediaSvg from '../../assets/images/svg/piramedia_icon.svg';
import { useResetFormServices } from '../../hooks/useResetFormServices';
import { useSiteMetadata, socialNetworks } from '../../hooks/useSiteMetadata';

export function Footer() {
  const { navBannerHeightMobile } = useSelector(state => state.app);
  const { company, siteNavigation } = useSiteMetadata();
  const { resetFormServices } = useResetFormServices();
  const currentYear = new Date().getFullYear();

  const piramediaIcon = (
    <Icon>
      <img
        src={piramediaSvg}
        alt={`logo of ${company.partners[0].title}`}
      />
    </Icon>
  );

  const scrollToTop = e => {
    const anchor = (e.target.ownerDocument || document).querySelector(
      '#to-top',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  };

  const handleResetFormServices = target => {
    if (target === 'contact') {
      resetFormServices();
    }

    return;
  };

  return (
    <Box
      component="footer"
      sx={{
        pb: {
          xs: `${navBannerHeightMobile}px`,
          sm: 0,
        },
      }}
    >
      <Box
        className="grey-light"
        component="section"
        sx={{ ...useStyles.root }}
      >
        <Reveal>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md>
              <Stack
                direction="column"
                alignItems="flex-start"
                sx={{ ...useStyles.nav }}
              >
                <Subtitle>Navigation</Subtitle>
                {siteNavigation.links.map((val, i) => (
                  <Button
                    key={i}
                    to={val.url}
                    sx={{ display: val.ref === 'services' ? 'none' : 'inline-flex' }}
                    onClick={() => handleResetFormServices(val.ref)}
                  >
                    {val.title}
                  </Button>
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md>
              <Stack
                direction="column"
                alignItems="flex-start"
                sx={{ ...useStyles.nav }}
              >
                <Subtitle>Services</Subtitle>
                {siteNavigation.services.map((val, i) => (
                  <Button
                    key={i}
                    to={val.url}
                    sx={{ justifyContent: val.ref === 'care' && 'flex-start' }}
                  >
                    {val.title}
                  </Button>
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md>
              <Stack
                direction="column"
                alignItems="flex-start"
                sx={{ ...useStyles.contactWrap }}
              >
                <Subtitle>Contact</Subtitle>
                {/* <Stack
                  mb={2}
                  direction="column"
                  alignItems="flex-start"
                >
                  <TextContent sx={{ ...useStyles.subtitle }}>
                    {company.geneva.headQuarter}
                  </TextContent>
                  <Link
                    underline="hover"
                    color="text.primary"
                    to={`tel:${company.geneva.tel}`}
                  >
                    {company.geneva.tel}
                  </Link>
                  <Link
                    to={company.geneva.googleMapsUrl}
                    target="_blank"
                    underline="hover"
                    color="text.primary"
                    rel="noopener noreferrer"
                  >
                    {company.geneva.address}
                  </Link>
                </Stack>
                <TextContent sx={{ ...useStyles.subtitle }}>
                  {company.fribourg.headQuarter}
                </TextContent> */}
                <Box sx={{ ...useStyles.contactLinkWrap }}>
                  <Link
                    underline="hover"
                    color="text.primary"
                    to={`tel:${company.fribourg.tel}`}
                  >
                    {company.fribourg.tel}
                  </Link>
                  <br />
                  <Link
                    target="_blank"
                    underline="hover"
                    color="text.primary"
                    rel="noopener noreferrer"
                    to={company.fribourg.googleMapsUrl}
                  >
                    {company.fribourg.address}
                  </Link>
                </Box>
                <Stack
                  spacing={1}
                  direction="row"
                  sx={{ ...useStyles.socialNetworksWrap }}
                >
                  {socialNetworks.map((val, i) => (
                    <Tooltip
                      arrow
                      key={i}
                      placement="bottom"
                      title={val.title}
                    >
                      <IconButton
                        to={val.url}
                        size="small"
                        target="_blank"
                        underline="none"
                        aria-label={val.title}
                        rel="noopener noreferrer"
                        sx={{ ...useStyles.icon }}
                      >
                        {val.icon}
                      </IconButton>
                    </Tooltip>
                  ))}
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md>
              <Stack
                direction="column"
                alignItems="flex-start"
                sx={{ ...useStyles.partnersWrap }}
              >
                <Subtitle>Partenaire</Subtitle>
                {company.partners.map((val, i) => {
                  // const icon = i === 0 ? piramediaIcon : i === 1 ? careIcon : techIcon;
                  const icon = piramediaIcon;
                  return (
                    <Button
                      key={i}
                      to={val.url}
                      underline="none"
                      startIcon={icon}
                    >
                      {val.title}
                    </Button>
                  )
                })}
              </Stack>
            </Grid>
          </Grid>
        </Reveal>
      </Box>
      <Box
        component="section"
        className="grey-darker footer"
        sx={{ ...useStyles.footerBottomBanner }}
      >
        <Reveal>
          <Stack direction="row" sx={{ ...useStyles.linkWrap }}>
            <Link
              to="/legal/"
              color="secondary"
              sx={{ ...useStyles.link }}
            >
              <TextContent component="span">
                Mentions légales
              </TextContent>
            </Link>
            <Link to="/privacy-policy/" color="secondary">
              <TextContent component="span">
                Politique de confidentialité
              </TextContent>
            </Link>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <TextContent
              component="span"
              sx={{ ...useStyles.copyright }}
            >
              &copy;{currentYear} {company.title}
            </TextContent>
            <Tooltip placement="top" title="Retourner en haut" arrow>
              <IconButton
                color="secondary"
                onClick={scrollToTop}
                aria-label="back to top"
              >
                <KeyboardArrowUpRounded />
              </IconButton>
            </Tooltip>
          </Stack>
        </Reveal>
      </Box>
    </Box>
  );
};
