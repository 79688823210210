import { customization } from '../../layout/Theme';

export const useStyles = {
  root: {
    p: 2,
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100%',
    overflow: 'hidden',
    pointerEvents: 'none',
    background: customization.colors.blackOverlay,
    transition: theme => theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enterScreen,
    }),
  },

  loader: {
    width: {
      xs: '100%',
      md: customization.breakpoints.md,
    },
  },
};