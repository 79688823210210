import React from 'react';
import { Link } from 'gatsby-theme-material-ui';

import CookieConsent from 'react-cookie-consent';

import { TextContent } from '../utils/utils';
import { useStyles } from './cookieBanner.styles';

export function CookieBanner() {
  return (
    <CookieConsent
      // debug={true}
      location="bottom"
      enableDeclineButton
      buttonText="Accepter"
      declineButtonText="Refuser"
      style={{ ...useStyles.root }}
      cookieName="gatsby-gdpr-google-analytics"
      buttonStyle={{ ...useStyles.button, ...useStyles.buttonAccept }}
      declineButtonStyle={{ ...useStyles.button, ...useStyles.buttonDecline }}
    >
      <TextContent variant="body2">
        En poursuivant votre navigation, vous acceptez l'utilisation de cookies pour vous garantir une bonne expérience, ainsi que de mesurer et d'améliorer les performances de ce site. &nbsp;<Link to="/privacy-policy/" color="secondary" underline="always"><strong>En savoir plus</strong></Link>.
      </TextContent>
    </CookieConsent>
  );
};