import React from 'react';
import { Provider } from 'react-redux';
import { SnackbarProvider, useSnackbar } from 'notistack';

import { Slide, IconButton, useMediaQuery } from '@mui/material';
import { ErrorRounded, CheckCircleRounded, CloseRounded } from '@mui/icons-material';

import store from './store';
import Theme from './layout/Theme';
import Layout from './layout/Layout';
import { useStyles } from './app.styles';

function CloseSnackbarAction({ id }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      color="secondary"
      aria-label="close Snackbar"
      onClick={() => closeSnackbar(id)}
      sx={{ ...useStyles.closeSnackbarButton }}
    >
      <CloseRounded />
    </IconButton>
  );
};

export default function App({ location, children }) {
  const matches = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <Provider store={store}>
      <Theme>
        <SnackbarProvider
          preventDuplicate
          autoHideDuration={7500}
          TransitionComponent={Slide}
          action={key => <CloseSnackbarAction id={key} />}
          anchorOrigin={{
            horizontal: 'center',
            vertical: matches ? 'top' : 'bottom',
          }}
          iconVariant={{
            error: <ErrorRounded sx={{ ...useStyles.icon }} />,
            success: <CheckCircleRounded sx={{ ...useStyles.icon }} />,
          }}
        >
          <Layout
            location={location}
            children={children}
          />
        </SnackbarProvider>
      </Theme>
    </Provider>
  );
};