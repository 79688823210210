export const useStyles = {
  titleBig: {
    fontWeight: theme => theme.typography.fontWeightBold,
  },

  title: {
    m: theme => theme.spacing(0, 0, 3),
    fontWeight: theme => theme.typography.fontWeightBold,
  },

  subtitle: {
    mb: theme => theme.spacing(1),
    fontWeight: theme => theme.typography.fontWeightBold,

    '&.regular': {
      fontWeight: theme => theme.typography.fontWeightRegular,
    },
  },
};