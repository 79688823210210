import React from 'react';

import { LinkedIn } from '@mui/icons-material';
import { useStaticQuery, graphql } from 'gatsby';

export function useSiteMetadata() {
  const { site } = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          author
          title
          description
          keywords
          siteName
          siteUrl
          imageUrl
          siteNavigation {
            links {
              title
              ref
              url
              description
            }
            services {
              title
              ref
              url
              description
            }
          }
          company {
            title
            manager
            email
            team {
              filename
              name
              job
              description
            }
            geneva {
              headQuarter
              tel
              address
              googleMapsTarget
              googleMapsUrl
            }
            fribourg {
              headQuarter
              tel
              address
              googleMapsTarget
              googleMapsUrl
            }
            partners {
              title
              url
            }
          }
        }
      }
    }
  `);

  return site.siteMetadata;
};

export const socialNetworks = [
  {
    title: 'LinkedIn',
    icon: <LinkedIn />,
    url: 'https://www.linkedin.com/company/piramedia-tech/',
  },
];