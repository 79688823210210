module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"D:\\nodejs\\piramedia\\website\\tech_v2\\src\\App.js"},
    },{
      plugin: require('../node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"fr","dir":"ltr","start_url":"/","name":"Piramedia Tech","display":"fullscreen","theme_color":"#FFF","short_name":"Piramedia Tech","background_color":"#FFF","icon":"D:\\nodejs\\piramedia\\website\\tech_v2/static/favicon_1000.png","icons":[{"sizes":"192x192","type":"image/png","src":"/favicon_192.png"},{"sizes":"512x512","type":"image/png","src":"/favicon_512.png"}],"icon_options":{"purpose":"any maskable"},"description":"Piramedia Tech avec le sourire, c'est encore plus facile ! Nous sommes une société spécialisée dans la réception, la transmission, le traitement et le stockage des données, et nous vous simplifions la gestion multicanal de vos flux de données.","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9b35a7a28a59c8655a81903faef623fb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://piramedia-tech.ch/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-2ZSM3M2SZ9","anonymize":true,"cookieName":"gatsby-gdpr-google-analytics"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
