import React from 'react';

import { grey, green, red } from '@mui/material/colors';
import { darkScrollbar } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';

export const customization = {
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },

  colors: {
    white: '#FFF',
    primary: '#1C73B9',
    greyLight: grey[50],
    grey: grey[200],
    greyDark: grey[600],
    greyDarker: grey[800],
    blackOverlay: 'rgba(0, 0, 0, 0.85)',
  },

  typography: {
    fontFamily: [
      'Comfortaa',
      'sans-serif',
    ].join(','),
    letterSpacing: 0.5,
    lineHeight: '150%',
    regular: 400,
    bold: 700,
  },

  borderRadius: {
    small: 2,
    medium: 4,
    large: 6,
    rounded: '50%',
  },

  px: {
    small: 8,
    medium: 12,
    default: 16,
    large: 32,
    xlarge: 48,
  },

  transitions: {
    easeInOut: 'all 0.3s ease-in-out',
    cubicExpoInOut: 'cubic-bezier(0.77, 0, 0.175, 1)',
    expoInOut: 'all 0.5s cubic-bezier(0.77, 0, 0.175, 1)',
  },
};

const customTheme = createTheme({
  spacing: 8,

  typography: {
    fontFamily: customization.typography.fontFamily,
    lineHeight: '150%',
  },

  // colors
  palette: {
    primary: {
      main: customization.colors.primary,
    },

    secondary: {
      main: customization.colors.white,
    },

    success: {
      main: green[500],
    },

    error: {
      main: red[500],
    },

    background: {
      default: customization.colors.white,
      paper: customization.colors.grey,
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          ...darkScrollbar(
            {
              track: customization.colors.grey,
              thumb: grey[500],
              active: customization.colors.greyDark,
            }
          ),
        },

        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          maxWidth: '100vw',
          minHeight: '100vh',
          overflow: 'hidden',
          backgroundColor: customization.colors.white,

          '& *, & ::before, & ::after': {
            boxSizing: 'border-box',
            lineHeight: `${customization.typography.lineHeight}`,
            letterSpacing: `${customization.typography.letterSpacing}px !important`,
          },

          '&::-moz-selection, & *::-moz-selection, &::-webkit-selection, & *::-webkit-selection, &::selection, & *::selection': {
            color: customization.colors.white,
            backgroundColor: customization.colors.greyDarker,
          },

          '&.loaded': {
            overflow: 'hidden visible',
          },

          '& .centered': {
            textAlign: 'center',
          },

          // container
          '& section': {
            margin: 0,
            width: '100%',
            position: 'relative',
            backgroundColor: customization.colors.white,

            '&.grey-light': {
              backgroundColor: customization.colors.greyLight,
            },

            '&.grey-dark': {
              color: customization.colors.white,
              backgroundColor: customization.colors.greyDark,

              '& p': {
                color: customization.colors.greyLight,
              },
            },

            '&.grey-darker': {
              color: customization.colors.white,
              backgroundColor: customization.colors.greyDarker,

              '& p': {
                color: customization.colors.greyLight,
              },
            },

            // responsive padding
            '&:not(.hero, .about, .footer)': {
              padding: `7.5vh ${customization.px.default}px`,

              [`@media all and (min-width: ${customization.breakpoints.sm}px)`]: {
                padding: '10vh 10vw',

                '&.services-list': {
                  // paddingTop: 0,
                },
              },
            },

            '&.button': {
              paddingTop: 0,
            },

            // wrapper content
            '& .reveal:not(.image)': {
              width: '100%',
              margin: 'auto',
              maxWidth: customization.breakpoints.md,

              '&.grid': {
                width: 'unset',
                maxWidth: 'unset',
              },

              '&.fill-height': {
                height: '100%',
              },
            },
          },

          // gatsby image
          '& .gatsby-image-wrapper': {
            '&.hero-image': {
              width: '100%',
              height: '35vh',

              [`@media all and (min-width: ${customization.breakpoints.sm}px)`]: {
                height: '45vh',
              },
            },

            '&.about-image': {
              height: '35vh',
              maxHeight: 'unset',

              [`@media all and (min-width: ${customization.breakpoints.md}px)`]: {
                height: '100%',
                maxHeight: 435,
              },
            },

            '&.image-rounded': {
              '& img': {
                borderRadius: '50%',
              },

              [`@media all and (min-width: ${customization.breakpoints.sm}px)`]: {
                maxWidth: 200,
              },
            },

            '&.card-image': {
              '& img': {
                height: 150,
                objectFit: 'contain !important',
                backgroundColor: customization.colors.greyLight,
              },
            },
          },

          /* SNACKBAR NOTIFICATION */
          '& .SnackbarItem-contentRoot': {
            paddingRight: '45px !important',
            paddingLeft: '45px !important',
          },

          /* MUI CUSTOMIZATION */
          // remove shadow
          '& .MuiPaper-root, & .MuiButton-root, & .MuiButton-root:hover, & .MuiButton-root:active': {
            boxShadow: 'none',
          },

          // keep shadow for dropdown
          '& .MuiPopover-paper': {
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
          },

          // remove top-bottom padding in menu list
          '& .MuiList-root': {
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: customization.colors.white,
          },

          // button
          '& .MuiButton-root': {
            textTransform: 'none',

            '&.MuiButton-contained:not(.contact)': {
              width: '100%',

              [`@media all and (min-width: ${customization.breakpoints.sm}px)`]: {
                width: 'unset',
              },
            },
          },

          // tooltip
          '& .MuiTooltip-popper': {
            [`@media all and (max-width: ${customization.breakpoints.md}px)`]: {
              display: 'none',
            },
          },

          // input message
          '& .MuiFormHelperText-contained': {
            margin: `${customization.px.small}px 0 0 0`,
          },
        },
      },
    },
  },
});

// include theme
export default function Theme({ children }) {
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
