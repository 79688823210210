import React from 'react';

import { Typography } from '@mui/material';

import { useStyles } from './utils.styles';

export function TitleBig(props) {
  const titleTag = props.component || 'h1';
  const titleVariant = props.variant || 'h4';

  return (
    <Typography
      {...props}
      component={titleTag}
      variant={titleVariant}
      sx={{ ...useStyles.titleBig }}
    >
      {props.children}
    </ Typography>
  );
};

export function Title(props) {
  const headingTag = props.component || 'h2';

  return (
    <Typography
      {...props}
      variant="h5"
      component={headingTag}
      sx={{
        ...useStyles.title,
        mb: props.removeMb ? 0 : useStyles.title.m
      }}
    >
      {props.children}
    </Typography>
  );
};

export function Subtitle(props) {
  const headingTag = props.component || 'h3';

  return (
    <Typography
      {...props}
      variant="h6"
      component={headingTag}
      sx={{
        ...useStyles.subtitle,
        mb: props.removeMb ? 0 : useStyles.subtitle.mb
      }}
    >
      {props.children}
    </Typography>
  );
};

export function TextContent(props) {
  return (
    <Typography {...props}>
      {props.children}
    </Typography>
  );
};