import { customization } from '../../layout/Theme';
import logo from '../../assets/images/svg/logo_dark.svg';

export const useStyles = {
  root: {
    top: 0,
    zIndex: 10,
    position: 'sticky',
    backgroundColor: customization.colors.white,
    borderBottom: `0.25px solid ${customization.colors.primary}`,
  },

  nav: {
    width: '100%',
    margin: 'auto',
    maxWidth: customization.breakpoints.md,
  },

  logo: {
    borderRadius: '50%',
    transition: theme => theme.transitions.create(['width', 'height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    '& .nav-logo': {
      width: '100%',
      height: '100%',
      background: `url(${logo}) no-repeat center`,
    },

    '&.disabled': {
      pointerEvents: 'none',
    },
  },

  linkWrap: {
    justifyContent: {
      xs: 'space-evenly',
      md: 'space-between',
    },

    '& li': {
      listStyleType: 'none',
    },

    '& a': {
      fontSize: theme => theme.typography.pxToRem(15),

      '&:not(.contact, .subNavLink)': {
        color: 'text.primary',

        '&.active': {
          color: 'primary.main',

          '&::after': {
            content: '""',
            display: 'block',
            width: '25%',
            bottom: theme => theme.spacing(0.25),
            position: 'absolute',
            borderBottom: `2px solid ${customization.colors.primary}`,
          },
        },

        '& .MuiButton-endIcon svg': {
          transition: theme => theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),

          '&.active': {
            transform: 'rotate(-180deg)',
          },
        },
      },
    },
  },

  dropdownWrap: {
    '& ul': {
      minWidth: 150,
    },

    '& a.active': {
      color: 'white',
      bgcolor: 'primary.light',
    },
  },

  subNavLink: {
    pt: 1,
    pb: 1,
    fontSize: theme => theme.typography.pxToRem(15),
  },
};