import { customization } from '../../layout/Theme';
import logo from '../../assets/images/svg/logo_dark.svg';

export const useStyles = {
  root: {
    zIndex: 10,
    // position: 'sticky',
    // top: 0,
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: customization.colors.white,
    borderTop: `1px solid ${customization.colors.primary}`,
  },

  banner: {
    px: 1,
    minHeight: 70,
  },

  logo: {
    width: 60,
    height: 60,
    borderRadius: '50%',

    '& .nav-logo': {
      width: '100%',
      height: '100%',
      background: `url(${logo}) no-repeat center`,
    },

    '&.disabled': {
      pointerEvents: 'none',
    },
  },

  trigger: {
    color: 'text.primary',
  },

  nav: {
    zIndex: -1,
    position: 'fixed',
    top: 0,
    left: 0,
    // bottom: 0,
    width: '100%',
    height: '100vh',
    overflow: 'auto',
    transform: 'translateX(100%)',
    // pt: theme => `calc(54px + ${theme.spacing(1)})`,
    backgroundColor: customization.colors.white,
  },

  linkWrap: {
    '& a:not(.contact, .subNavLink)': {
      '&.active': {
        color: 'white',
        bgcolor: 'primary.main',
      },

      '& .MuiSvgIcon-root': {
        transition: theme => theme.transitions.create(['transform'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),

        '&.active': {
          transform: 'rotate(-180deg)',
        },
      },
    },
  },

  paddingLink: {
    pt: 2,
    pb: 2,
  },

  contact: {
    mt: 0.5,
  },

  dropdownWrap: {
    pl: 2,

    '& li': {
      listStyleType: 'none',
    },

    '& a.active span': {
      color: 'primary.main',
      fontWeight: theme => theme.typography.fontWeightBold,
    },
  },
};