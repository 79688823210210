// first render of client DOM
export const onInitialClientRender = () => {
  document.body.classList.add('loaded');
};

// keep current position to prevent scrollTop before when changing route
export const shouldUpdateScroll = () => {
  return false;
};

// scroll to top when changing location
export const onRouteUpdate = () => {
  setTimeout(() => { window.scrollTo(0, 0); }, 400);
};
