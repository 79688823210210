import { createSlice } from '@reduxjs/toolkit';

// create globale state
const initialState = {
  is404: false,
  isLoading: true,
  isFormServices: [],
  isScrollToServices: false,
  navBannerHeightMobile: null,
  navBannerHeightDesktop: null,
};

const appSlice = createSlice({
  name: 'app',
  initialState,

  reducers: {
    setIs404: (state, action) => {
      state.is404 = action.payload;
    },
    toggleLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsFormServices: (state, action) => {
      state.isFormServices = action.payload;
    },
    setIsScrollToServices: (state, action) => {
      state.isScrollToServices = action.payload;
    },
    setNavBannerHeightMobile: (state, action) => {
      state.navBannerHeightMobile = action.payload;
    },
    setNavBannerHeightDesktop: (state, action) => {
      state.navBannerHeightDesktop = action.payload;
    },
  },
});

export const {
  setIs404,
  toggleLoader,
  setIsFormServices,
  setIsScrollToServices,
  setNavBannerHeightMobile,
  setNavBannerHeightDesktop
} = appSlice.actions;

export default appSlice.reducer;