import React, { useEffect } from 'react';

import { Box } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export function Reveal({ children, delay, sx, className }) {
  // animation settings
  const variants = {
    hidden: {
      y: 50,
      opacity: 0,
    },

    visible: {
      y: 0,
      opacity: 1,

      transition: {
        delay: delay,
        duration: 0.75,
        ease: 'easeOut',
      },
    },
  };

  // target element
  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.1 });

  useEffect(() => {
    inView && controls.start('visible');
  }, [controls, inView]);

  return (
    <Box
      sx={sx}
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={variants}
      component={motion.div}
      className={`${className} reveal`}
    >
      {children}
    </Box>
  );
};