export const useStyles = {
  closeSnackbarButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },

  icon: {
    position: 'absolute',
    top: 10,
    left: 10,
  },
};