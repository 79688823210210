import { useDispatch, useSelector } from 'react-redux';

import { setIsFormServices } from '../store/appSlice';

export function useResetFormServices() {
  const dispatch = useDispatch();
  const { isFormServices } = useSelector(state => state.app);

  const resetFormServices = () => isFormServices.length > 0 && dispatch(setIsFormServices([]));

  return { resetFormServices };
};