import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useMediaQuery } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';

import { toggleLoader } from '../store/appSlice';
import { Loader } from '../components/loader/Loader';
import { Footer } from '../components/footer/Footer';
import { CookieBanner } from '../components/cookies/CookieBanner';
import { HeaderMobile } from '../components/header/Header.mobile';
import { HeaderDesktop } from '../components/header/Header.desktop';

// page transition settings
const duration = 0.3;
const variants = {
  initial: {
    opacity: 0,
  },

  enter: {
    opacity: 1,

    transition: {
      ease: 'easeInOut',
      duration: duration,
    },
  },

  exit: {
    opacity: 0,

    transition: {
      ease: 'easeInOut',
      duration: duration,
    },
  },
};

export default function Layout({ location, children }) {
  const dispatch = useDispatch();
  const { is404, isLoading } = useSelector(state => state.app);
  const currentLocation = location.pathname;
  const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

  // hide loader onload
  useEffect(() => {
    dispatch(toggleLoader(false));
  }, []);

  return (
    <>
      <Loader active={isLoading} />

      <CookieBanner />

      <div id="to-top" />

      {is404 ? (
        ''
      ) : matches ? (
        <HeaderDesktop location={currentLocation} />
      ) : (
        <HeaderMobile location={currentLocation} />
      )}

      <AnimatePresence exitBeforeEnter>
        <motion.main
          exit="exit"
          animate="enter"
          initial="initial"
          variants={variants}
          key={currentLocation}
        >
          {children}
        </motion.main>
      </AnimatePresence>

      {is404 ? (
        ''
      ) : (
        <Footer />
      )}
    </>
  );
};