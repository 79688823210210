import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Button, IconButton } from 'gatsby-theme-material-ui';

import {
  Box,
  List,
  Stack,
  ListItem,
  Collapse,
  ListItemText,
  ListItemButton
} from '@mui/material';
import { motion } from 'framer-motion';
import { ExpandMoreRounded } from '@mui/icons-material';

import { useStyles } from './header.mobile.styles';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';
import { setNavBannerHeightMobile } from '../../store/appSlice';
import { useResetFormServices } from '../../hooks/useResetFormServices';

// nav animation settings
const navVariants = {
  open: {
    y: 0,

    transition: {
      duration: 0.2,
      ease: 'easeOut',
    },
  },

  closed: {
    y: '100%',

    transition: {
      delay: 0.2,
      duration: 0.2,
      ease: 'easeIn',
    },
  }
};

// nav item animation settings
const navLinkVariants = {
  open: {
    y: 0,
    opacity: 1,

    transition: {
      delay: 0.15,
    }
  },

  closed: {
    y: 100,
    opacity: 0,

    transition: {
      y: {
        stiffness: 1000,
      },
    }
  }
};

export function HeaderMobile({ location }) {
  const dispatch = useDispatch();
  const { siteNavigation } = useSiteMetadata();
  const { resetFormServices } = useResetFormServices();

  // store nav height
  const navBanner = useRef(null);
  useEffect(() => {
    if (navBanner) {
      dispatch(setNavBannerHeightMobile(navBanner.current.offsetHeight));
    }
  }, [navBanner]);

  // handle dropdown
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = e => {
    e.preventDefault();
    setIsDropdownOpen(!isDropdownOpen);
  };
  const closeDropdown = () => setIsDropdownOpen(false);

  // handle nav display
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [handleNavAnimation, setHandleNavAnimation] = useState('');
  const closeNav = () => {
    isNavOpen && setIsNavOpen(false);
    isDropdownOpen && closeDropdown();
  };
  const toggleNav = () => {
    closeNav();
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {
    isNavOpen ? setHandleNavAnimation('open') : setHandleNavAnimation('closed');
  }, [isNavOpen]);

  return (
    <Box component="header" sx={{ ...useStyles.root }}>
      <Stack
        ref={navBanner}
        direction="row"
        alignItems="center"
        sx={{ ...useStyles.banner }}
        justifyContent="space-between"
      >
        <IconButton
          to="/"
          size="large"
          onClick={closeNav}
          aria-label="back home"
          sx={{ ...useStyles.logo }}
          className={location === '/' ? 'disabled' : ''}
        >
          <Box className="nav-logo" />
        </IconButton>
        {isNavOpen ? (
          <Button color="error" onClick={toggleNav}>
            Fermer
          </Button>
        ) : (
          <Button
            onClick={toggleNav}
            sx={{ ...useStyles.trigger }}
          >
            Menu
          </Button>
        )}
      </Stack>
      <Box
        initial={false}
        component={motion.nav}
        variants={navVariants}
        sx={{ ...useStyles.nav }}
        animate={handleNavAnimation}
      >
        <List
          component="ul"
          disablePadding
          sx={{ ...useStyles.linkWrap }}
        >
          {siteNavigation.links.map((val, i) => (
            <motion.li key={i} variants={navLinkVariants}>
              {val.ref === 'services' ? (
                <>
                  <ListItemButton
                    to={val.url}
                    component={Link}
                    partiallyActive={true}
                    activeClassName="active"
                    onClick={toggleDropdown}
                    sx={{ ...useStyles.paddingLink }}
                  >
                    <ListItemText primary={val.title} />
                    <ExpandMoreRounded className={isDropdownOpen ? 'active' : ''} />
                  </ListItemButton>
                  <Collapse in={isDropdownOpen}>
                    <List component="ul" sx={{ ...useStyles.dropdownWrap }}>
                      {siteNavigation.services.map((val, i) => (
                        <Box component="li" key={i}>
                          <ListItemButton
                            to={val.url}
                            component={Link}
                            onClick={closeNav}
                            className="subNavLink"
                            activeClassName="active"
                            sx={{ ...useStyles.paddingLink }}
                          >
                            <ListItemText primary={val.title} />
                          </ListItemButton>
                        </Box>
                      ))}
                    </List>
                  </Collapse>
                </>
              ) : (
                val.ref === 'contact' ? (
                  <ListItem
                    component="span"
                    sx={{ ...useStyles.paddingLink }}
                  >
                    <Button
                      to={val.url}
                      color="primary"
                      onClick={() => {
                        closeNav();
                        resetFormServices();
                      }}
                      className={val.ref}
                      sx={{ ...useStyles.contact }}
                      variant={location === val.url ? 'contained' : 'outlined'}
                    >
                      {val.title}
                    </Button>
                  </ListItem>
                ) : (
                  <ListItemButton
                    to={val.url}
                    component={Link}
                    onClick={closeNav}
                    activeClassName="active"
                    sx={{ ...useStyles.paddingLink }}
                  >
                    <ListItemText primary={val.title} />
                  </ListItemButton>
                )
              )}
            </motion.li>
          ))}
        </List>
      </Box>
    </Box>
  );
};
